import { Button, Form, Input, PageHeader } from 'antd';
import React, { useEffect } from 'react';
import moment from 'moment';
import DashBoardL from '../../components/dashboardL';
import { config } from '../../constant';
import { openNotificationWithIcon } from '../../common';
const MatrixInput = () =>{
    const [form] = Form.useForm();
    const getMatrixInput = async() =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let api = '';
        api = config().API_URL+"/GetMatrixInput";
        let requestOptions = {
            method: 'GET',
            headers: myHeaders
        }
        await fetch(api, requestOptions).then(response => response.json()).then(async(resp: any) => {
            if(resp && resp.d)  {
                const response = JSON.parse(resp.d);
                if(Array.isArray(response) && response.length>0)
                form.setFieldsValue({
                    "baggageScanners": response[0].baggageScanners,
                    "doorframe": response[0].doorframe,
                    "handheld": response[0].handheld,
                    "explosivedetectors": response[0].explosivedetectors,
                    "vehiclescanners": response[0].vehiclescanners
                  });
            }

        });
    }
    const onFinish = async(values: any) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let api = '';
        if(values.baggageScanners && values.doorframe && values.handheld 
            && values.explosivedetectors && values.vehiclescanners){
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                  "baggageScanners": values.baggageScanners,
                  "doorframe": values.doorframe,
                  "handheld": values.handheld,
                  "explosivedetectors": values.explosivedetectors,
                  "vehiclescanners": values.vehiclescanners
                })
              };
              api = config().API_URL+"/AddMatrixInput";
              await fetch(api, requestOptions).then(response => response.json()).then(async(resp: any) => {  
                openNotificationWithIcon("Success", "Success", "Matrix Input Updated!!!");
              });
        } else {
            openNotificationWithIcon("Error", "Error", "Validation Failed");
        }
    }
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
      };
      useEffect(()=>{
        getMatrixInput();
      },[])
    return (<><DashBoardL>
        <>
        <PageHeader
            className="site-page-header"
            title="Matrix Input"
            subTitle="View"
        />
        <Form
          form = {form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 4 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
                <Form.Item
                    label="X-RAY BAGGAGE SCANNERS"
                    name="baggageScanners"
                    rules={[{ required: true, message: 'Required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="DOOR FRAME METAL DETECTORS"
                    name="doorframe"
                    rules={[{ required: true, message: 'Required' }]}
                >
                    <Input />
                </Form.Item>
     
                <Form.Item
                    label="HAND HELD METAL DETECTORS"
                    name="handheld"
                    rules={[{ required: true, message: 'Required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="EXPLOSIVE DETECTORS"
                    name="explosivedetectors"
                    rules={[{ required: true, message: 'Required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="VEHICLE MOUNT SCANNERS"
                    name="vehiclescanners"
                    rules={[{ required: true, message: 'Required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
  <Button type="primary" htmlType="submit">
    Submit
  </Button>
</Form.Item>
                </Form>
        </>
    </DashBoardL>;</>)
}


export default MatrixInput;